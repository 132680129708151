.ways-to-give h2 {
	text-transform: uppercase;
	font-size: 1.6em;
}

.ways-to-give .giving-section.hero {
	font-weight: bold;
	border: 0;
	background-image: url("../assets/images/Claudia-6.jpg");
 	background-color: #cccccc;	
 	background-repeat: no-repeat;
 	background-size: cover;
 	height: 655px;
 	background-position: center bottom;
 	margin-top: 2em;
 	position: relative;
}

.ways-to-give .giving-section.hero .hero-content {
	position: absolute;
	bottom: 1.2em;
	right: 0;
	width: 100%;
	text-align: center;
}

.ways-to-give .giving-section.hero h1 {
	color: #fff;
	text-shadow: 0 0 0.3em #000;
	margin: 0 auto;
	max-width: 14em;
}

.ways-to-give .giving-section.hero+p.copy {
	padding: 2em 10%;
}

.ways-to-give p.copy {
	font-size: 1.25em;
}

.ways-to-give p.copy.nut-graf {
	margin-bottom: 0;
	font-weight: bold;
}

.ways-to-give p.copy.inset {
	padding-left: 1.5em;
}

.ways-to-give .giving-section {
	border-top: 3px solid #ccc;
	padding: 2em 10%;
}

.ways-to-give .action-button-container {
	text-align: center;
	padding: 1em;
}

.ways-to-give .action-button {
/* inspired by .GenericPagePromoModule-CTALink */
    display: inline-block;
    background-color: rgb(193,0,21);
    font-size: 1.2rem;
    font-weight: bold;
    letter-spacing: 0;
    padding: 14px 24px 12px;
    text-transform: uppercase;
    text-align: center;
    margin: auto;
    border-radius: 2px;
    font-weight: 600;
    color: #fff;
    letter-spacing: 3px;
    text-decoration: none;
    transition: transform 0.1s; 
}
.ways-to-give .action-button:hover {
    background-color: rgb(252,57,22);
    color: #fff;
	transform: scale(1.02); 
}
.ways-to-give .action-link, .ways-to-give .action-link:visited  {
	color: rgb(193,0,21);
	box-shadow: 0 1px 0 rgb(193,0,21);
	font-weight: bold;
}

@font-face {
  font-family: Sharp Grotesk;
  font-display: auto;
  src: url(../assets/fonts/SharpGroteskSmBold15.woff) format(woff), url(../assets/fonts/SharpGroteskSmBold15.woff2) format(woff2);
  font-weight: 900;
}
@font-face {
  font-family: Sharp Grotesk;
  font-display: auto;
  src: url(../assets/fonts/SharpGroteskSmBoldItalic15.woff) format(woff), url(../assets/fonts/SharpGroteskSmBoldItalic15.woff2) format(woff2);
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: Sharp Grotesk;
  font-display: auto;
  src: url(../assets/fonts/SharpGroteskMedium20.woff) format(woff), url(../assets/fonts/SharpGroteskMedium20.woff2) format(woff2);
  font-weight: 700;
}
@font-face {
  font-family: Sharp Grotesk;
  font-display: auto;
  src: url(../assets/fonts/SharpGroteskMediumItalic20.woff) format(woff), url(../assets/fonts/SharpGroteskMediumItalic20.woff2) format(woff2);
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: Sharp Grotesk;
  font-display: auto;
  src: url(../assets/fonts/SharpGroteskBook20.woff) format(woff), url(../assets/fonts/SharpGroteskBook20.woff2) format(woff2);
  font-weight: 400;
}
@font-face {
  font-family: Sharp Grotesk;
  font-display: auto;
  src: url(../assets/fonts/SharpGroteskBookItalic20.woff) format(woff), url(../assets/fonts/SharpGroteskBookItalic20.woff2) format(woff2);
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: Sharp Grotesk;
  font-display: auto;
  src: url(../assets/fonts/SharpGroteskLight20.woff) format(woff), url(../assets/fonts/SharpGroteskLight20.woff2) format(woff2);
  font-weight: 200;
}
@font-face {
  font-family: Sharp Grotesk;
  font-display: auto;
  src: url(../assets/fonts/SharpGroteskLightItalic20.woff) format(woff), url(../assets/fonts/SharpGroteskLightItalic20.woff2) format(woff2);
  font-weight: 200;
  font-style: italic;
}